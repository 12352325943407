import React, { ReactNode } from 'react';
import classes from './SplitRow.module.scss';
import clsx from 'clsx';
import Txt from 'components/atoms/Txt/Txt';
import Column from 'components/atoms/Column/Column';
import PageContentWrapper from 'components/templates/Page/PageContentWrapper';

export interface Props {
  className?: string;
  leftColumn?: ReactNode;
  rightColumn?: ReactNode;
  id?: string;
  rowTitle?: string;
  contentAlignedTop?: boolean;
  mobileColumnOrderSwapped?: boolean;
  hasBorderBottom?: boolean;
}

const SplitRow: React.FC<Props> = ({
  className,
  leftColumn,
  rightColumn,
  id,
  rowTitle,
  contentAlignedTop,
  mobileColumnOrderSwapped,
  hasBorderBottom,
}) => {
  const columnContentAlignedTopClass = contentAlignedTop ? classes.contentAlignedTop : undefined;

  const columns = (
    <>
      {leftColumn && (
        <Column
          className={clsx(
            classes.column,
            columnContentAlignedTopClass,
            mobileColumnOrderSwapped && classes.mobileColumnOrderSecond,
          )}
        >
          {leftColumn}
        </Column>
      )}
      {rightColumn && (
        <Column className={clsx(classes.column, columnContentAlignedTopClass)}>
          {rightColumn}
        </Column>
      )}
    </>
  );

  if (rowTitle)
    return (
      <PageContentWrapper id={id}>
        <div className={clsx(classes.columnContainer, hasBorderBottom && classes.borderBottom)}>
          <Txt component="h2" className={classes.rowTitle}>
            {rowTitle}
          </Txt>
          <div className={clsx(classes.root, className, classes.hasTitle)}>{columns}</div>
        </div>
      </PageContentWrapper>
    );

  return (
    <PageContentWrapper id={id}>
      <div
        className={clsx(
          classes.root,
          classes.columnContainer,
          hasBorderBottom && classes.borderBottom,
          contentAlignedTop && classes.contentAlignedTop,
        )}
      >
        {columns}
      </div>
    </PageContentWrapper>
  );
};

export default SplitRow;
