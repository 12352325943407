import clsx from 'clsx';
import { ButtonColor, ButtonType } from 'components/atoms/Button/Button';
import React from 'react';
import Button from '../../../../src/components/atoms/Button/Button';
import useTranslate from '../../../hooks/useTranslate';
import classes from './Form.module.scss';

export interface FormProps {
  buttonWrapperClassName?: string;
  className?: string;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  submitDisabled?: boolean;
  submitHidden?: boolean;
  submitName?: string;
  children: React.ReactNode;
  submitButtonColor?: ButtonColor;
  submitButtonType?: ButtonType;
  submitButtonFullWidth?: boolean;
}

const Form: React.FC<FormProps> = ({
  className,
  buttonWrapperClassName,
  onSubmit,
  submitName,
  submitDisabled,
  submitHidden,
  children,
  submitButtonColor,
  submitButtonType,
  submitButtonFullWidth,
  ...rest
}) => {
  const translate = useTranslate();

  return (
    <form aria-label="form" className={clsx(classes.root, className)} onSubmit={onSubmit}>
      {children}
      {!submitHidden && (
        <div className={clsx(classes.buttonWrapper, buttonWrapperClassName)}>
          <Button
            component="input"
            type="submit"
            buttonSize="large"
            value={translate(submitName || 'Submit')}
            disabled={submitDisabled}
            className={clsx(classes.submit, submitButtonFullWidth && classes.submitFullWidth)}
            color={submitButtonColor ?? 'primary'}
            buttonType={submitButtonType ?? 'secondary'}
          />
        </div>
      )}
    </form>
  );
};

export default Form;
