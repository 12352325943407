import LandingPage from 'components/pages/LandingPage/LandingPage';
import Page, { PageOptions } from 'components/templates/Page/Page';
import LANDING_PAGES from 'constants/landingPages';
import { GetStaticProps, GetStaticPropsContext, PreviewData } from 'next/types';
import { ParsedUrlQuery } from 'querystring';
import React from 'react';
import { Environment, GraphQLTaggedNode, fetchQuery } from 'react-relay';
import { Variables } from 'relay-runtime';
import createRelayEnvironment from 'relay/createRelayEnvironment';

type QueryVarGenerator = (context: GetStaticPropsContext<ParsedUrlQuery, PreviewData>) => Variables;

export function buildRelayEnvironment(locale: string) {
  return createRelayEnvironment({
    getAccessToken: () => Promise.resolve(null),
    onError: (err) => console.warn(err),
    locale: locale === 'default' ? 'en-us' : locale,
  });
}

export function buildQueryPreloader(
  query: GraphQLTaggedNode,
  variables?: Variables | QueryVarGenerator,
): GetStaticProps {
  const getStaticProps: GetStaticProps = async function getStaticProps(context) {
    const environment = buildRelayEnvironment(context.locale || 'en-us');
    const vars = typeof variables === 'function' ? variables(context) : variables;
    await fetchQuery(environment as Environment, query, vars || {}).toPromise();
    const relayEnv = environment.getStore().getSource().toJSON();
    return {
      revalidate: 30 * 60,
      props: {
        relayEnv: relayEnv,
      },
    };
  };
  return getStaticProps;
}

export function buildPage<T>(Component: React.ComponentType<T>, options: PageOptions) {
  return function BuildPage(props: T) {
    return (
      <Page {...options}>
        <Component {...props} />
      </Page>
    );
  };
}

export function buildLandingPage(keyword: string) {
  return buildPage(
    () => <LandingPage leftColumnContent={LANDING_PAGES[keyword].leftColumnContent} />,
    {
      fullPage: true,
      hasMobileSignUpBanner: true,
      loggedInRedirect: '/dashboard',
      unlisted: true,
      ...LANDING_PAGES[keyword],
    },
  );
}
