import React from 'react';
import classes from './HeroSignUpForm.module.scss';
import EntryForm from '../EntryForm/EntryForm';
import OinqSuccess from 'components/assets/oinq/oinq_success_shadow.svg';
import OinqSuccessUsa from 'components/assets/oinq/oinq_success_shadow_usa.svg';
import OinqSuccessAus from 'components/assets/oinq/oinq_success_shadow_aus.svg';
import OinqSuccessCan from 'components/assets/oinq/oinq_success_shadow_can.svg';
import LocalisedSvg from 'components/atoms/LocalisedSvg/LocalisedSvg';

export interface Props {}

const HeroSignUpForm: React.FC<Props> = () => {
  return (
    <div className={classes.root}>
      <EntryForm
        className={classes.signUpForm}
        title="Sign up and start earning"
        subtitle="It's quick and easy"
      />
      <LocalisedSvg
        localisedSvgs={{
          global: OinqSuccess,
          'en-us': OinqSuccessUsa,
          'en-au': OinqSuccessAus,
          'en-ca': OinqSuccessCan,
        }}
        className={classes.welcomeOinq}
      />
    </div>
  );
};

export default HeroSignUpForm;
