import React from 'react';
import Script from 'next/script';
import Txt from 'components/atoms/Txt/Txt';
import Image from 'components/atoms/Image/Image';
import AppStoreLinks from 'components/atoms/AppStoreLinks/AppStoreLinks';
import { HOW_IT_WORKS, CASHBACK, SIGN_UP } from 'components/pages/pages';
import homepageImage1 from './assets/homepage_img_1.png';
import homepageImage1_en_gb from './assets/homepage_img_1_en-gb.png';
import homepageImage1_de_de from './assets/homepage_img_1_de-de.png';
import homepageImage1_fr_fr from './assets/homepage_img_1_fr-fr.png';
import homepageImage2 from './assets/homepage_img_2.png';
import homepageImage2_en_gb from './assets/homepage_img_2_en-gb.png';
import homepageImage2_de_de from './assets/homepage_img_2_de-de.png';
import homepageImage2_fr_fr from './assets/homepage_img_2_fr-fr.png';
import homepageImage3 from './assets/homepage_img_3.png';
import homepageImage3_de_de from './assets/homepage_img_3_de-de.png';
import homepageImage3_fr_fr from './assets/homepage_img_3_fr-fr.png';
import homepageImage4 from './assets/homepage_img_4.png';
import homepageImage4_en_gb from './assets/homepage_img_4_en-gb.png';
import homepageImage4_de_de from './assets/homepage_img_4_de-de.png';
import homepageImage4_fr_fr from './assets/homepage_img_4_fr-fr.png';
import TrustpilotWidget from 'components/organisms/TrustpilotWidget/TrustpilotWidget';
import SplitRow from 'components/organisms/SplitRow/SplitRow';
import SplitRowCta from 'components/organisms/SplitRow/SplitRowCta';
import FullWidthCta from 'components/organisms/FullWidthCta/FullWidthCta';
import PageContentWrapper from 'components/templates/Page/PageContentWrapper';
import useLocalisedImage from 'hooks/useLocalisedImage';

export interface Props {}

const LandingPageContent: React.FC<Props> = () => {
  const localisedImage = useLocalisedImage();

  return (
    <div>
      <Script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />

      <PageContentWrapper>
        <TrustpilotWidget title="We're kind of a big deal..." />
      </PageContentWrapper>

      <SplitRow
        id="landingPageScrollToRow"
        leftColumn={
          <Image
            src={localisedImage({
              global: homepageImage1,
              'en-gb': homepageImage1_en_gb,
              'de-de': homepageImage1_de_de,
              'fr-fr': homepageImage1_fr_fr,
            })}
            alt="What is a paid survey site?"
          />
        }
        rightColumn={
          <>
            <Txt component="h2">Earn anytime, anywhere</Txt>
            <Txt component="p">
              Make some money on your commute, get some extra cash in your downtime, or earn while
              you study. Download our iOS or Android app, or use Qmee from your browser, and let
              Qmee work for you.
            </Txt>
            <AppStoreLinks hideRating />
          </>
        }
      ></SplitRow>

      <SplitRow
        mobileColumnOrderSwapped
        rightColumn={
          <Image
            src={localisedImage({
              global: homepageImage2,
              'en-gb': homepageImage2_en_gb,
              'de-de': homepageImage2_de_de,
              'fr-fr': homepageImage2_fr_fr,
            })}
            alt="Cash out however you want, whenever you want"
          />
        }
        leftColumn={
          <>
            <Txt component="h2">Cash out however you want, whenever you want</Txt>
            <Txt component="p">
              Have your rewards any way you choose! With no minimum cashout amount, and a variety of
              ways to withdraw your earnings including PaPal, Venmo and gift cards, you can cashout
              whenever you like, however you like.
            </Txt>
          </>
        }
      ></SplitRow>

      <SplitRow
        leftColumn={
          <Image
            src={localisedImage({
              global: homepageImage3,
              'de-de': homepageImage3_de_de,
              'fr-fr': homepageImage3_fr_fr,
            })}
            alt="Your opinion matters"
          />
        }
        rightColumn={
          <>
            <Txt component="h2">Your opinion matters</Txt>
            <Txt component="p">
              There&apos;s a reason survey providers pay for completed surveys - your opinion
              matters to them! By answering surveys with Qmee you can get your voice heard and{' '}
              <strong>influence your favorite brands</strong>.
            </Txt>
            <SplitRowCta to={SIGN_UP.path}>Sign up and start earning</SplitRowCta>
          </>
        }
      ></SplitRow>

      <SplitRow
        mobileColumnOrderSwapped
        rightColumn={
          <Image
            src={localisedImage({
              global: homepageImage4,
              'en-gb': homepageImage4_en_gb,
              'de-de': homepageImage4_de_de,
              'fr-fr': homepageImage4_fr_fr,
            })}
            alt="Earn &amp; save when you shop with Qmee"
          />
        }
        leftColumn={
          <>
            <Txt component="h2">Earn &amp; save when you shop with Qmee</Txt>
            <Txt component="p">
              Qmee not only has paid surveys - our hand-picked money earners let you earn real cash
              when you make a purchase. You can also get yourself some money off when you use our
              exclusive discount codes!
            </Txt>
            <SplitRowCta to={CASHBACK.path}>Show me cashback offers</SplitRowCta>
          </>
        }
      ></SplitRow>

      <FullWidthCta
        title="How do paid surveys work?"
        ctaText="Tell me more"
        ctaLink={HOW_IT_WORKS.path}
      >
        Get provided with online market research surveys that best match your profile,
        <br />
        then get a cash reward whenever you complete a survey.
      </FullWidthCta>
    </div>
  );
};

export default LandingPageContent;
