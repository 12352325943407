import React, { ReactNode } from 'react';
import classes from './SplitRowCta.module.scss';
import Txt from 'components/atoms/Txt/Txt';
import A from 'components/atoms/A/A';

export interface Props {
  children: string;
  to: string;
}

const SplitRowCta: React.FC<Props> = ({ children, to }) => {
  return (
    <div className={classes.ctaContainer}>
      <Txt className={classes.root} component={A} to={to}>
        {children}
      </Txt>
    </div>
  );
};

export default SplitRowCta;
