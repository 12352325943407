import React, { ReactNode } from 'react';
import classes from './SignUpMobileBanner.module.scss';
import { SIGN_UP } from 'components/pages/pages';
import Txt from 'components/atoms/Txt/Txt';
import A from 'components/atoms/A/A';
import OinqSuccess from 'components/assets/oinq/oinq_success.svg';
import OinqSuccessUsa from 'components/assets/oinq/oinq_success_usa.svg';
import OinqSuccessAus from 'components/assets/oinq/oinq_success_aus.svg';
import OinqSuccessCan from 'components/assets/oinq/oinq_success_can.svg';
import LocalisedSvg from 'components/atoms/LocalisedSvg/LocalisedSvg';

export interface Props {}

const SignUpMobileBanner: React.FC<Props> = () => {
  return (
    <A to={SIGN_UP.path} className={classes.root}>
      <Txt component="p" className={classes.title}>
        Sign up and start earning
      </Txt>
      <Txt component="p" className={classes.description}>
        It&apos;s quick and easy
      </Txt>
      <LocalisedSvg
        localisedSvgs={{
          global: OinqSuccess,
          'en-us': OinqSuccessUsa,
          'en-au': OinqSuccessAus,
          'en-ca': OinqSuccessCan,
        }}
        className={classes.welcomeOinq}
      />
    </A>
  );
};

export default SignUpMobileBanner;
